import revive_payload_client_yTfNqgnGED from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fq01XaYURc from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_xbl1baZ48F from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Z2lIuntcJB from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5Rs9GRVyVw from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_vssm9j3kev from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_GDoExMzIOt from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Kmd3bnh9lG from "/usr/src/node-app/node_modules/.pnpm/@pinia+nuxt@0.5.3_magicast@0.3.4_rollup@4.18.0_typescript@5.5.4_vue@3.4.38_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/node-app/.nuxt/components.plugin.mjs";
import prefetch_client_jZGLwqPG5Z from "/usr/src/node-app/node_modules/.pnpm/nuxt@3.12.3_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_ti2qzddfisrb7xw7hlxkcye5yq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/usr/src/node-app/.nuxt/formkitPlugin.mjs";
import plugin_Xh0p4h0AQ8 from "/usr/src/node-app/node_modules/.pnpm/@nuxt+icon@1.4.5_magicast@0.3.4_rollup@4.18.0_vite@5.3.3_@types+node@22.3.0_terser@5.31.6__vue@3.4.38_typescript@5.5.4_/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import i18n_jMYrHAVJRE from "/usr/src/node-app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_70NOotg1Aw from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/sentry/runtime/plugin.client.ts";
import plugin_QLWetDO1y3 from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/store/runtime/plugin.ts";
import plugin_ZRyyVCAL4W from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/04.error/runtime/plugin.ts";
import conversion_plugin_smIVqsVkho from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/runtime/conversion-plugin.ts";
import login_plugin_W95XpSmMWs from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/01.authentication/runtime/login-plugin.ts";
import plugin_BGiTKm6QEn from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/02.integrations/runtime/plugin.ts";
import umami_wsmGDYyzCw from "/usr/src/node-app/node_modules/.pnpm/nuxt-umami@2.6.4/node_modules/nuxt-umami/plugins/umami.ts";
import _3_user_opBP2pWs67 from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/plugins/3.user.ts";
import _5_crisp_client_D5Nuw6duPL from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/plugins/5.crisp.client.ts";
export default [
  revive_payload_client_yTfNqgnGED,
  unhead_fq01XaYURc,
  router_xbl1baZ48F,
  payload_client_Z2lIuntcJB,
  navigation_repaint_client_5Rs9GRVyVw,
  check_outdated_build_client_vssm9j3kev,
  chunk_reload_client_GDoExMzIOt,
  plugin_vue3_Kmd3bnh9lG,
  components_plugin_KR1HBZs4kY,
  prefetch_client_jZGLwqPG5Z,
  formkitPlugin_pZqjah0RUG,
  plugin_Xh0p4h0AQ8,
  i18n_jMYrHAVJRE,
  plugin_client_70NOotg1Aw,
  plugin_QLWetDO1y3,
  plugin_ZRyyVCAL4W,
  conversion_plugin_smIVqsVkho,
  login_plugin_W95XpSmMWs,
  plugin_BGiTKm6QEn,
  umami_wsmGDYyzCw,
  _3_user_opBP2pWs67,
  _5_crisp_client_D5Nuw6duPL
]