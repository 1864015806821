import { default as deletedYgdhAJ1c7wMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/deleted.vue?macro=true";
import { default as privacyC3xM2ulDf2Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/en/privacy.vue?macro=true";
import { default as tosumMPxMOPZpMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/en/tos.vue?macro=true";
import { default as imprintwkRcJ4qSDaMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/imprint.vue?macro=true";
import { default as indexi5ieDgxCAWMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/index.vue?macro=true";
import { default as privacyQ6HbpINcXXMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/privacy.vue?macro=true";
import { default as settingsDjLNatiDQyMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/settings.vue?macro=true";
import { default as smatching_45demoVnznsc4qBYMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/smatching-demo.vue?macro=true";
import { default as toskNoPY1tyorMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/tos.vue?macro=true";
import { default as loginebO7WlKfePMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/login.vue?macro=true";
import { default as sign_45upNJ0qhRtix3Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/sign-up.vue?macro=true";
import { default as forgot_45password04JLFDy6a3Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/forgot-password.vue?macro=true";
import { default as indexeDfhWK55uAMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/index.vue?macro=true";
import { default as invite90kgLSlOwFMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/invite.vue?macro=true";
import { default as smatchingZYqEoYG9dyMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/smatching.vue?macro=true";
import { default as help9cXCmOCFj5Meta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/help.vue?macro=true";
import { default as indexixpZcHgKwbMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/index.vue?macro=true";
import { default as ticketsv8Ddq4NtGDMeta } from "/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/tickets.vue?macro=true";
export default [
  {
    name: "deleted",
    path: "/deleted",
    meta: deletedYgdhAJ1c7wMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/deleted.vue").then(m => m.default || m)
  },
  {
    name: "en-privacy",
    path: "/en/privacy",
    meta: privacyC3xM2ulDf2Meta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/en/privacy.vue").then(m => m.default || m)
  },
  {
    name: "en-tos",
    path: "/en/tos",
    meta: tosumMPxMOPZpMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/en/tos.vue").then(m => m.default || m)
  },
  {
    name: "imprint",
    path: "/imprint",
    meta: imprintwkRcJ4qSDaMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyQ6HbpINcXXMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: "smatching-demo",
    path: "/smatching-demo",
    meta: smatching_45demoVnznsc4qBYMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/smatching-demo.vue").then(m => m.default || m)
  },
  {
    name: "tos",
    path: "/tos",
    meta: toskNoPY1tyorMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/pages/tos.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginebO7WlKfePMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upNJ0qhRtix3Meta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password04JLFDy6a3Meta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/01.authentication/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-invite",
    path: "/profile/invite",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/profile/invite.vue").then(m => m.default || m)
  },
  {
    name: "smatching",
    path: "/smatching",
    meta: smatchingZYqEoYG9dyMeta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/smatching.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    meta: help9cXCmOCFj5Meta || {},
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "lottery",
    path: "/lottery",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/index.vue").then(m => m.default || m)
  },
  {
    name: "lottery-tickets",
    path: "/lottery/tickets",
    component: () => import("/usr/src/node-app/node_modules/.pnpm/@smatched+nuxt-core@1.11.10_@formkit+core@1.6.5_@formkit+inputs@1.6.5_encoding@0.1.13_ioredis_l3553bzoi4jale7habhxqshbau/node_modules/@smatched/nuxt-core/modules/03.core/pages/lottery/tickets.vue").then(m => m.default || m)
  }
]